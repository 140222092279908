<template>

  <div :class="'fI-slow login-page  mobile_' + isMobile()">

    <div id="loginWidget">
      <div class="pulsate loginWidgetText">
        <div>~ <translate>REGISTER</translate> ~</div>
      </div>
    </div>

    <!--LoginWidgets /-->

    <div style="overflow: hidden;">

      <FarmgodHeader>
        <div class='row' style="top: 10%; position: sticky;">
          <div class='col-sm-12'>
            <h1 id="logo_main" class="teaserLogoMain">
              <Logo v-bind:link="'none'" v-bind:title="'Powered by FarmGod.io'"/>
            </h1>
          </div>
        </div>
      </FarmgodHeader>

      <div  style="width: 100%; overflow: hidden;">

        <!-- IMPORTANT: conditionally use "display: none;" to hide the fields rather than v-if
          since that will clear the values and cause email resending to fail -->
        <FormulateForm
          v-model='values'
          :errors='errors'
          @submit="createAccount()"
          #default="{ hasErrors }"
          :style="awaitConfirmation ? 'display: none' : ''"
        >
          <div id="registerForm">
            <div v-if='errors.root' class='landingPageInput item-errors alert alert-danger'>
              <translate>Register error:</translate> { errors.root }
            </div>

            <div class="logo_spacer_top"></div>
            <div class="loginFormWrapper">
              <FormulateInput
                  class="landingPageInput"
                  name='email'
                  autocomplete="new-password"
                  type='email'
                  validation='email'
                  error-behavior='blur'
                  :placeholder="$gettext('Enter a valid e-mail address...')"
                  style="margin-bottom: 5px;"
              />
            </div>
            <div class="logo_spacer_bottom"></div>
            <div class="form-group">
              <button v-if='hasErrors' disabled class="landingPageButton btn btn-primary trans" style="opacity: 0.3; cursor: not-allowed;"><i class="fas fa-user fas-left"></i><translate>CREATE ACCOUNT</translate></button>
              <button v-else class="landingPageButton btn btn-success"><i class="fas fa-user fas-left"></i><translate>CREATE ACCOUNT</translate></button>
              <br/>
              <router-link class='loginSubBtn w-50 mt-2 btn btn-outline-secondary btn-small' exact :to="{ name: 'login' }">
                <translate>Return to login</translate>
              </router-link>
            </div>
          </div>

        </FormulateForm>
        <div v-if="awaitConfirmation" style="width: 100%;" class="fI">
          <div class="logo_spacer_top"></div>
            <div class="loginFormWrapper fI-slow" style="padding: 28px 0 29px 0;">
              <b><translate>You got mail!</translate></b> <translate>Follow the instructions to activate your account.</translate>
            </div>
          <div class="logo_spacer_bottom"></div>
          <a class='loginSubBtn w-50 mt-2 btn btn-outline-secondary btn-small fI' @click="createAccount()">
            <translate>Resend the email</translate>
          </a>
        </div>

      </div>

    </div>

    <FarmgodFooter>
      <LoggedOutFooter />
    </FarmgodFooter>

  </div>

</template>

<script>
import { AUTH__REGISTER } from '@/store/actions.type'
import FarmgodHeader from '@/components/layout/TheHeader'
import Logo from '@/components/layout/Logo'

import FarmgodFooter from '@/components/layout/TheFooter'
import LoggedOutFooter from '@/components/layout/Footers/LoggedOut'

export default {
  name: 'Register',
  components: { FarmgodHeader, Logo, FarmgodFooter, LoggedOutFooter },
  data () {
    return {
      values: {
        email: ''
      },
      awaitConfirmation: false,
      errors: {}
    }
  },
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    handleErrors (err) {
      if (!err.response) {
        this.errors = { root: 'There was a server error, please check your connection' }
        return
      }
      if (parseInt(err.response.status) === 400) {
        this.errors = { root: err.response.data.detail }
      } else if (parseInt(err.response.status) === 422) {
        const errorsObject = { email: [] }
        err.response.data.detail
          .map((err) => {
            errorsObject[err.loc[1]].push(err.msg)
          })
        this.errors = errorsObject
      }
    },
    createAccount () {
      this.$store
        .dispatch(AUTH__REGISTER, {
          ...this.values
        })
        .then(() => {
          this.errors = {}
          this.awaitConfirmation = true
        })
        .catch((err) => {
          this.handleErrors(err)
          if (err.response.status === 400) {
            this.$router.push({ name: 'forgot_password' })
          }
        })
    }
  }
}
</script>

<style>
  @import '../assets/style/sessions.scss';
</style>
